/* Profile Image */
.form-set .uploads input {
  display: none;
  visibility: hidden;
}

.form-set .uploads label {
  all: unset !important ;
}

.form-set .uploads {
  display: flex;
  padding-left: 15px;
  padding-right: 15px;
}

.form-set .galleries-images {
  display: flex;
  width: fit-content;
}

.form-set .uploads .limit-images {
  padding-top: 30px;
  padding-left: 20px;
  color: var(--lightred);
  font-size: 12px;
}

.form-set .uploads .no-images {
  display: flex;
  flex-flow: column;
  width: 90px;
  height: 69px;
  margin-top: 5px;
  border: 3px solid #f8f8f8;
  background: #f1f1f1;
  cursor: pointer;
  justify-content: center;
  text-align: center;
  align-items: center;
  filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.15));
}

.form-set .uploads .no-images p {
  font-size: 8px;
  padding: 0px 2px 5px 2px;
  color: rgba(139, 139, 139, 1);
}

.form-set .uploads .material-icons {
  color: rgba(139, 139, 139, 1);
}

.form-set .uploads .material-icons:hover {
  color: black;
}

.galleries-container {
  display: flex;
  flex-flow: row wrap;
  height: fit-content;
  filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.15));
}

.galleries-container .image-name-container {
  display: flex;
  flex-flow: row wrap;
  position: relative;
}

.galleries-container .image-name-container img {
  object-fit: cover;
  justify-content: center;
  margin: auto;
  width: 85px;
  height: 110px;
  min-height: 110px;
  min-width: 85px;
  border: 3px solid #f8f8f8;
  margin: 2px;
}

.galleries-container .image-name-container.prev img {
  border: 1px dashed black;
  padding: 1px;
}

.form-set .uploads .image-name-container img.zoom {
  display: block;
  width: 60%;
  height: fit-content;
  top: 10px !important;
  left: 10px !important;
  background-color: black;
}

/*
.setDelete {
  border: 1px solid red;
  position: absolute;
  left: 0;
  top: 0;
  background-color: red;
}
*/

/* delete image */
.galleries-container .images-delete {
  display: flex;
  flex-flow: column;
  margin: 3px;
  background-color: rgba(255, 126, 126, 0.3);
  border-radius: 2px;
  border: 1px solid white;
}

.galleries-container .delete-buttons {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}

.galleries-container .delete-image-yes,
.galleries-container .delete-image {
  user-select: none;
  display: flex;
  flex-flow: column;
  margin: 0 6px 0 6px;
}

.galleries-container .delete-image-yes {
  color: rgb(1, 1, 1, 0.5);
  position: relative;
  top: -95px;
  right: -25px;
  font-size: 30px;
  justify-content: center;
  align-items: center;
  height: 0;
}

.galleries-container .delete-image-yes::after {
  font-family: "Material Icons";
  content: "\e92b";
  position: absolute;
  font-weight: lighter;
}

.galleries-container .delete-image-yes:hover {
  cursor: pointer;
  opacity: 0.5;
}

.galleries-container .delete {
  /* background-color: rgba(255, 126, 126, 0.2);*/
  margin: 4px;
  padding: 1px;
  border-radius: 3px;
  border: 1px solid white;
  cursor: pointer;
  border: 1px dotted rgb(255, 146, 146);
}

.delete img {
  background-color: rgb(255, 255, 255, 0.1);
  opacity: 0.5;
  z-index: 100;
}
