.top {
  display: flex;
  height: fit-content;
  margin: 0px 0px 0px 0;
  flex-grow: 1;
  justify-content: right;
  align-items: center;
  text-align: center;
  z-index: 3;
  position: relative;
  top: -5px;
}

.top-logout-container {
  padding-right: 5px;
}

.top-logout-container button {
  all: unset;
  width: 102px;
  height: 20px;
  font-size: 10px;
  background-color: #fed47e;
  cursor: pointer;
}

.top-logout-container button:hover {
  color: #fed47e;
  background-color: black;
}
