main {
  display: flex;
  flex-flow: column;
  background: #d9d9d9;
  /*
  background: no-repeat url(../../../assets/shine-bg-main.jpg);
  background-size: cover;
  background-position: 50% 60%;
  background-blend-mode: lighten;
  background-color: rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  */
  height: 100%;
  min-height: calc(100vh - (80px + 60px));
}

.hidden,
.hide {
  display: none;
  visibility: hidden;
}

.show {
  display: flex;
  visibility: visible;
}
