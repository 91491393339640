/* Profile Image */
.form-set .upload {
}

.form-set .upload input {
  display: none;
}

.form-set .upload .profile-image {
  display: flex;
  flex-flow: column;
  width: 175px;
  height: 230px;
  background: #eaeaea;
  border: 6px solid #f8f8f8;
  justify-content: center;
  text-align: center;
  align-items: center;
  filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.15));
}

.form-set .upload .material-icons.big-person {
  font-size: 90px;
}

.form-set .upload .profile-image .image-name img {
  object-fit: cover;
  justify-content: center;
  margin: auto;
  width: 164px;
  height: 218px;
  padding: 0px;
  padding-top: 0px;
}

#drag-file-element {
  border: 1px dashed #959595;
  border-radius: 2px;
  cursor: grab;
}

.disabled {
  border: 0px dashed #959595;
  border-radius: 2px;
  cursor: initial;
}
