.login {
  position: relative;
  display: flex;
  flex-flow: column;
  height: fit-content;
  background: no-repeat url(../../../assets/shine-bg-main.jpg);
  background-size: cover;
  background-position: bottom;
  background-blend-mode: lighten;
  background-color: rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  justify-content: center;
  padding-top: 60px;
  padding-bottom: 50px;
  align-items: center;
}

.login-form {
  z-index: 1;
  display: flex;
  flex-flow: column;
  width: fit-content;
  justify-content: right;
  align-items: center;
  text-align: right;
  position: relative;
  transition: all 1s ease-out;
  width: 100%;
}

.login input {
  all: unset;
  width: 229px;
  height: 31px;
  background: #d9d9d9;
  text-align: left;
  font-size: 12px;
  padding-left: 10px;
  text-transform: initial !important;
}

.login label,
.login .recovery {
  display: flex;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  justify-content: right;
  text-align: right;
  padding: 8px 0 8px 0;
}

.login .recovery {
  padding: 0;
  padding-top: 8px;
}

.login form .login-button {
  display: flex;
  margin: auto;
  margin: 23px 0 19px 0;
  justify-content: right;
}

.login .login-button button {
  all: unset;
  background: #fed47e;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  color: var(--black);
  text-align: center;
  height: 25px;
  width: 89px;
  cursor: pointer;
}

.login .login-button button:hover {
  opacity: 0.85;
  background: #000000;
  color: #fed47e;
}

.login .error.bg {
  background-color: black;
  padding: 10px;
  border-radius: 5px;
}

/* LANDING */
.main_form {
  display: flex;
  position: relative;
  flex-flow: column;
  border-radius: 42px;
  opacity: 0.800000011920929;
  background: #d9d9d9;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  width: 374px;
  flex-shrink: 0;
  padding: 35px 51px 0px 29px;
  -webkit-animation: fade-in 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  margin-bottom: 30px;
}

.main_form_hide,
.hide_el {
  width: 374px;
  height: 661px;
  -webkit-animation: fade-out 1s ease-out both;
  animation: fade-out 1s ease-out both;
  transition: all 0.5s ease-in-out;
}

@media screen and (max-width: 376px) {
  .main_form {
    width: 350px;
  }
}

/* TOP CONTENT */
.main_form h1 {
  position: relative;
  color: #000;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  max-height: 100%;
  width: 116px;
  margin-bottom: 8px;
}

.main_form h4 {
  position: relative;
  display: flex;
  flex-flow: column;
  height: 24px;
  width: 143px;
  margin-bottom: 37px;
  color: #000;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: lowercase;
  text-align: left;
}

/* FORM CONTAINER */
.main_form form {
  position: relative;
  display: flex;
  flex-flow: column;
  width: 292px;
  height: fit-content;
  text-align: right;
}

.main_form .form-elements,
.main_form .form-element-name,
.main_form .form-element-files {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  text-align: right;
  margin-top: 18px;
  margin-bottom: 5px;
}
.main_form .form-element-name {
  margin-top: 0px;
}

.main_form .form-elements label,
.main_form .form-element-name label {
  display: flex;
  olor: #4d4d4d;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;

  text-transform: capitalize;
}

.main_form .form-elements input,
.main_form .form-element-name input {
  border-bottom: 1px solid #fff;
  background: #eaeaea;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25) inset;
  align-items: center;
}

.main_form .form-elements input {
  width: 151px;
  flex-shrink: 0;
}

.main_form .form-element-name input {
  width: 200px;
  flex-shrink: 0;
}

.main_form .form-element-files input {
  display: none;
}

.main_form .form-elements.last {
  margin-bottom: 5px;
}

/* UPLOAD CONTAINER */
.main_form .form-element-files {
  display: flex;
  justify-content: right;
  padding: 0px 0px 10px 10px;
  margin-bottom: 10px;
}

.main_form .form-element-files p {
  padding-bottom: 10px;
  text-transform: none;
}

.main_form .form-element-files label {
  display: flex;
  border: 0;
  width: 113px;
  height: 21px;
  margin-left: 100%;
  flex-shrink: 0;
  background: #fed47e;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  color: #8b8b8b;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
  margin-top: 3px;
}

.main_form .form-element-files label:hover {
  color: black;
  box-shadow: none;
}

/* BOTTOM CONTENT */
.main_form .form-elements-bottom {
  position: relative;
  display: flex;
  flex-flow: column;
}

.rc-anchor-center-item {
  width: 174px !important;
}

/* BUTTONS */
.main_form .form-elements-buttons {
  position: relative;
  display: flex;
  text-align: right;
  justify-content: right;
  margin: 0 !important;
  padding: 0 !important;
}

.main_form button {
  border: 0;
  width: 113px;
  height: 21px;
  flex-shrink: 0;
  background: #fed47e;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  color: #8b8b8b;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  margin-top: 10px;
}

.main_form button.button-off {
  background: var(--lightgray);
  box-shadow: none;
  color: #6a6969;
}

.main_form button:hover {
  color: black;
  box-shadow: none;
}

.main_form button.button-off:hover {
  color: #6a6969;
  cursor: not-allowed;
}

/* NAV BOTTOM */
.nav-buttons {
  position: absolute;
  display: flex;
  bottom: 40px;
  opacity: 1;
  transition: all 1s ease-in-out;
}

.nav-buttons:hover {
  opacity: 1;
}

@media screen and (min-width: 1921px) {
  .login {
    height: 85vh;
    padding-top: 60px;
    padding-bottom: 50px;
    align-items: center;
  }
}

.nav-buttons li::after {
  all: unset;
}

.nav-buttons ul {
  display: flex;
  flex-flow: row wrap;
  width: 374px;
}

.nav-buttons li {
  display: flex;
  flex-flow: row wrap;
  width: 113px;
  height: 21px;
  flex-shrink: 0;
  background: #fed47e;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  color: #8b8b8b;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 3px;
  margin: 0 0px 0 0px;
  margin: auto;
  text-align: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.nav-buttons li:hover,
.nav-buttons a:hover {
  box-shadow: none;
}

/* MAIN CONTENT BOTTOM */
.main-container-bottom {
  display: flex;
  background-color: #ffffff !important;
  justify-content: center;
  align-items: center;
}

.main-container {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  max-width: 1200px;
  width: 100%;
}

.main-social-media {
  position: relative;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 65px;
}

.main-social-media img {
  width: 200px;
}

.main-about {
  position: relative;
  display: flex;
  flex-flow: column;
  margin-bottom: 212px;
  padding: 25px;
}

.main-about h1 {
  display: flex;
  color: #040404;
  font-family: Inter;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 32px;
}

.about-text {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  width: 100%;
}

.about-text p {
  text-transform: initial !important;
  display: flex;
  flex-grow: 1;
  width: 50%;
  color: #000;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-right: 40px;
}

.about-image {
  position: relative;
  display: flex;
  flex-grow: 1;
  width: 50%;
}

@media screen and (max-width: 600px) {
  .about-image {
    display: none;
    visibility: hidden;
  }
  .main-about {
    padding: 25px;
  }
}

.about-image img {
  width: 522px;
  height: 432px;
  flex-shrink: 0;
}

.contact-icons-container {
  position: relative;
  display: flex;
  flex-flow: column;
}

.main-work {
  position: relative;
  display: flex;
  flex-flow: column;
  padding: 25px;
}

.work-top {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 32px;
  align-items: center;
  padding: 0px;
}

.work-top h1 {
  color: #040404;
  font-family: Inter;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-right: 34px;
}

.work-top p.p {
  margin: auto;
  margin-left: 0;
  font-size: 15px;
}

@media screen and (max-width: 376px) {
  .work-top p.p {
    margin-top: 0;
    padding-top: 0;
    font-size: 13px;
  }

  .work-top h1,
  .main-work p {
    padding: 25px;
    margin-top: 0;
    padding-top: 0;
  }
}

.work-top p,
.main-work p {
  text-transform: initial !important;
  color: #000;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.main-work p {
  margin-bottom: 40px;
}

.main-youtube {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin: auto;
}

.main-youtube img {
  max-width: 1200px;
}

@media screen and (max-width: 600px) {
  .main-youtube img {
    max-width: 375px;
    width: 100%;
  }
}

.main-video {
  display: flex;
  padding: 15px;
  justify-content: space-between;
}

.errorMsg {
  display: flex;
  padding: 15px;
  padding-right: 0px;
  color: #23211d;
  text-align: right;
  justify-content: right;
  text-transform: capitalize;
}

.error-message,
.error-files {
  color: #dc0000f5;
  text-transform: none;
  padding: 5px 10px 0px 10px;
}

.selected-files {
  text-transform: none;
  padding: 5px 10px 0px 10px;
}

.message {
  color: rgb(20, 19, 17);
}

.jump-top {
  z-index: 100;
  position: fixed;
  height: fit-content;
  width: fit-content;
  font-size: 40px;
  padding: 10px;
  right: 10px;
  bottom: 20px;
  color: white;
  background: rgba(30, 30, 30, 0.5);
  transition: all 1s ease-in-out;
  cursor: pointer;
  -webkit-animation: slide-top 1s cubic-bezier(0.075, 0.82, 0.165, 1) both;
  animation: slide-top 1s cubic-bezier(0.075, 0.82, 0.165, 1) both;
}

.material-symbols-outlined.jump-top:hover {
  color: var(--mainOrange);
}

@media screen and (max-width: 600px) {
  .jump-top {
    color: rgb(0, 0, 0);
    background: rgba(255, 255, 255, 0.8);
    border-radius: 3;
    box-shadow: var(--box-shadow);
    font-weight: 800;
  }
}

/* SOCIAL MEDIA NETWORKS*/
.contact-media {
  display: flex;
  flex-flow: row wrap;
  color: white;
  max-width: 100%;
  justify-content: left;
}

.contact-socials {
  display: flex;
  flex-flow: row wrap;
  padding-left: 10px;
}
.between {
  display: flex;
  flex-flow: row wrap;
  flex-grow: 1;
}

svg,
.media-fb,
.media-in,
.media-ig,
.media-tw,
.media-wa,
.media-gt,
.media-yt,
.media-email {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  /*filter: invert();*/
  margin: 0.5vw;
  margin-top: 25px;
  margin-bottom: 0px;
  filter: invert(65%) saturate(2878%) hue-rotate(190deg) brightness(104%)
    contrast(100%);
  fill: rgb(255, 255, 255);
}

svg:hover,
.media-fb:hover,
.media-in:hover,
.media-ig:hover,
.media-tw:hover,
.media-wa:hover,
.media-gt:hover,
.media-yt:hover,
.media-email:hover {
  cursor: pointer;
  filter: invert(0%) sepia(51%) saturate(1878%) hue-rotate(10deg)
    brightness(104%) contrast(100%);
  fill: rgb(255, 255, 255);
}

.media-fb {
  background-image: url("../../../assets/logo-facebook.svg");
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-animation: fade-in-fwd 0.8s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-fwd 0.8s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
.media-wa {
  background-image: url("../../../assets/logo-whatsapp.svg");
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-animation: fade-in-fwd 0.8s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-fwd 0.8s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
.media-tw {
  background-image: url("../../../assets/logo-twitter.svg");
  background-repeat: no-repeat;
  background-size: 50% cover;
  -webkit-animation: fade-in-fwd 0.8s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-fwd 0.8s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  margin-top: 29px;
}
.media-ig {
  background-image: url("../../../assets/logo-instagram.svg");
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-animation: fade-in-fwd 0.8s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-fwd 0.8s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
.media-in {
  background-image: url("../../../assets/logo-linkedin.svg");
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-animation: fade-in-fwd 0.8s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-fwd 0.8s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
.media-yt {
  background-image: url("../../../assets/logo-youtube.svg");
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-animation: fade-in-fwd 0.8s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-fwd 0.8s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.media-gt {
  background-image: url("../../../assets/logo-github.svg");
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-animation: fade-in-fwd 0.8s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-fwd 0.8s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
.media-email {
  background-image: url("../../../assets/logo-email.svg");
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-animation: fade-in-fwd 0.8s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-fwd 0.8s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.view-more {
  font-size: 15px;
  font-weight: 500;
  color: var(--darkgray);
  width: fit-content;
}

.view-more .material-icons {
  position: relative;
  top: 6px;
  padding: 0 10px 0 10px;
}

.view-more a {
  transition: all 0.5s ease-in-out;
}

.view-more .material-icons:hover,
.view-more a:hover {
  color: var(--gold);
  transition: all 0.5s ease-in-out;
}
