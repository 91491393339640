/* FILTER SLIDERS CONTAINER */
.slider-container {
  width: fit-content;
}

/* COMMON SLIDER */
.startvalue {
  margin-top: 7.97px;
}

.age-container,
.height-container,
.shoe-container,
.clothes-container {
  display: flex;
  justify-content: center;
}

.age-cursor,
.height-cursor,
.shoe-cursor,
.clothes-cursor {
  display: flex;
  width: 250px !important;
  margin: 12px 20px 0 20px;
}

.age-title,
.height-title,
.shoe-title,
.clothes-title {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: auto;
  font-size: 10px;
  padding-top: 12px;
}

.sliders-values {
  top: 37px;
  position: absolute;
  width: 250px;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: auto;
}

.sliders-values p {
  all: unset;
  -webkit-box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.26);
  box-shadow: 0px 0px 7px 1px rgba(0, 0, 0, 0.26);
  width: fit-content;
  background-color: white;
  padding: 1px 6px 1px 6px;
  border-radius: 10%;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: auto;
}

.sliders-values p::after {
  font-family: "Material Icons" !important ;
  content: "\e5c7" !important;
  color: white;
  font-size: 24px;
  position: absolute;
  top: -15px;
  right: 115px;
}

.age-cursor p,
.height-cursor p,
.shoe-cursor p,
.clothes-cursor p {
  padding: 0px 15px 0px 15px;
  font-size: 10px;
}

/* AGE SLIDER */
.age-container {
}
.age-cursor {
}
.age-title {
}
.age-cursor p {
}

/* HEIGHT SLIDER */
.height-container {
}
.height-cursor {
}
.height-title {
}
.height-cursor p {
}

/* SHOE SLIDER */
.shoe-container {
}
.shoe-cursor {
}
.height-title {
}
.shoe-cursor p {
}

/* CLOTHES SLIDER */
.clothes-container {
  padding: 0 !important;
  height: fit-content;
  max-height: 64px;
  margin-right: 10px;
}

.clothes-cursor {
}

.clothes-title {
  top: 0px;
}

.clothes-cursor p {
}

.left-bottom {
  display: flex;
  flex-flow: row;
  width: 100%;
  justify-content: left;
  text-align: left;
  margin-top: 10px;
}

/* CUSTOMIZE INPUT RADIO BUTTON */
input[type="radio"] {
  appearance: none;
  position: relative;
}

input[type="radio"]:after,
input[type="radio"]:checked:after {
  width: 12px;
  height: 12px;
  border-radius: 100%;
  position: relative;
  content: "";
  display: inline-flex;
  border-width: 0.5px;
}

input[type="radio"]:after {
  background-color: #d1d3d1;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

input[type="radio"]:checked:after {
  background-color: #fed47e;
  border: 1px solid rgba(0, 0, 0, 0);
}

/* MUI CSS OVERWRITE */
.MuiSlider-thumb {
  height: 12px !important;
  width: 12px !important;
  background-color: #fed47e !important;
  /*border: 1px solid #000000 !important;*/
}

.MuiSlider-thumb::after {
  height: 15px !important;
  width: 15px !important;
  background-color: #fed47e !important;
  /*border: 1px solid #000000 !important;*/
}

.MuiSlider-rail {
  height: 1px !important;
  background-color: black !important;
  opacity: 1;
  color: #000000 !important;
  border: none !important;
}

.MuiSlider-track {
  height: 1px !important;
  background-color: black !important;
  opacity: 1;
  color: #000000 !important;
  border: none !important;
}

.MuiSlider-markLabel.MuiSlider-markLabelActive.css-1eoe787-MuiSlider-markLabel {
  font-size: 8px !important;
  top: 22px !important;
  padding-top: 5px;
  color: var(--lightgray) !important;
}

.MuiSlider-markLabel.MuiSlider-markLabelActive.css-1eoe787-MuiSlider-markLabel {
  font-size: 8px !important;
  top: 22px !important;
  color: var(--darkgray) !important;
}

.MuiSlider-markLabel.MuiSlider-markLabel.css-yafthl-MuiSlider-markLabel {
  color: var(--lightgray) !important;
}

.MuiSlider-markLabel.css-yafthl-MuiSlider-markLabel {
  font-size: 8px !important;
  top: 22px !important;
  color: rgba(255, 255, 255, 0.2) !important;
}

/* Value Label Customization */
.MuiSlider-valueLabelOpen {
  -webkit-box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.26);
  box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.1);
  border-radius: 10% !important;
  padding: 0 0 0 0 !important;
  height: fit-content !important;
  width: fit-content !important;
  margin: 3px 0px 0px 0px !important;
  background-color: transparent;
}

.MuiSlider-valueLabelP {
  border: 1px solid rgb(220, 169, 0) !important;
}

.css-nnid7-MuiSlider-valueLabel {
  background-color: rgba(255, 255, 255, 1) !important;
}

.css-nnid7-MuiSlider-valueLabel::before {
  -webkit-box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.26);
  box-shadow: 0px 0px 7px 1px rgba(0, 0, 0, 0.26);
  top: 5px !important;
  z-index: -1 !important;
  /* width: 0 !important;
  height: 0 !important;
   font-family: "Material Icons" !important ;
  content: "\e5c5" !important;
  rotate: 315deg;*/
  color: red;
}

.MuiSlider-valueLabelCircle {
  /* border: 1px solid rgb(0, 162, 255) !important;*/
  background-color: rgba(255, 255, 255, 1) !important;
  z-index: 1;
  padding: 0px 4px 0px 4px !important;
  height: 16px !important;
  width: fit-content !important;
  text-align: center;
  border-radius: 20% !important;
}

.MuiSlider-valueLabelLabel {
  /*border: 1px solid rgb(230, 0, 255) !important;*/
  color: #000000 !important;
  font-size: 10px !important;
  width: fit-content !important;
  height: fit-content !important;
  top: -1px !important;
  position: relative !important;
  padding: 0px 2px 0px 2px !important;
}
