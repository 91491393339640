.clothes-container {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  width: fit-content;
  padding-left: 15px;
  align-items: center;
}

.cloth-title {
  font-size: 10px;
  color: var(--lightgray);
  margin-right: 10px;
}

.cloth-size p {
  display: flex;
  margin: 0;
  font-size: 10px;
  font-weight: 500;
  padding: 0 10px 0 10px;
  cursor: pointer;
  color: var(--lightgray);
}

.cloth-size p:hover {
  background-color: #fed47e;
}

.selected {
  background-color: #fed47e;
  color: var(--darkgray) !important;
  font-weight: 500 !important;
}
