.add-extra-features {
  display: flex;
  flex-flow: row wrap;
  height: 18px;
  width: 100%;
}

.add-extra-features-left {
  display: flex;
  flex-flow: row nowrap;
  height: 18px;
  flex-grow: 2;
}

.add-extra-features input {
  all: unset;
  height: 18px;
  font-size: 10px;
  line-height: 15px;
  color: rgba(139, 139, 139, 1);
  background: #eaeaea;
  border-bottom: 1px solid #ffffff;
  box-shadow: inset 0px 0px 3px rgba(0, 0, 0, 0.25);
  margin-bottom: 2px;
  padding-left: 5px;
}

.add-extra-features-title {
  all: unset;
  position: relative;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height */
  color: #8b8b8b;
}

.add-extra-features .icon {
  font-size: 15px;
  padding-left: 5px;
  padding-right: 5px;
  color: #8b8b8b;
  cursor: pointer;
}

.add-extra-features button {
  margin-left: 1px;
  background-color: var(--gold);
  height: fit-content;
}

.add-extra-features-right {
  display: flex;
  justify-content: right;
  text-align: right;
  align-items: center;
  padding-right: 10px;
  height: 18px;
  line-height: 18px;
  cursor: pointer;
}

.add-extra-features-right .down {
  position: absolute;
}

.add-extra-features-right .icon,
.material-icons.delete {
  position: relative;
  font-size: 15px;
  padding-left: 5px;
  padding-right: 5px;
  color: #8b8b8b;
  cursor: pointer;
}

.material-icons.delete {
  padding-right: 15px;
}
