:root {
  /* COLOR */
  --lightgray: #8b8b8b;
  --shadowgray: #eaeaea;
  --darkgray: #4d4d4d;
  --white: #ffffff;
  --lightred: #ff7e7e;
  --gold: #fed47e;

  /* SHADOW / GRADIENT */
  --font-shadow: 1px 1px 1px rgba(0, 0, 0, 0.09);
  --font-shadow-off: 2px 2px 2px rgba(0, 0, 0, 0);
  --box-shadow-light: 0px 1px 8px 1px rgba(0, 0, 0, 0.1);
  --button-shadow-light: 4px 1px 8px 1px rgba(0, 0, 0, 0.1);
  --box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.2);
  --box-shadow-hover: 0px 5px 8px 1px rgba(0, 0, 0, 0.3);
  --box-shadow-right: 3px 3px 16px -1px rgba(0, 0, 0, 0.21);
  --box-shadow-up: inset 0px 3px 8px -3px rgba(0, 0, 0, 0.28);
  --gradient: linear-gradient(
    0deg,
    rgba(255, 96, 96, 0.5) 0%,
    rgba(0, 0, 0, 0.5) 100%
  );

  /* FONT */
  --shrik: "Shrikhand", cursive;
  --fontawesome: "Font Awesome 5 Free";
  --robo: "Roboto", sans-serif;
  --robo-c: "Roboto Condensed", sans-serif;
  --material: "Material Icons";
  --inter: "Inter";
}
