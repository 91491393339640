.extra-features {
  display: flex;
  flex-flow: row wrap;
}

.extra-features-container {
  display: flex;
  flex-flow: row wrap;
  cursor: pointer;
  padding: 0 15px 0 15px;
}

.extra-features-container .tags {
  display: flex;
  background: var(--lightgray);
  color: white;
  margin: 3px;
}

.extra-features-container .tags:hover {
  display: flex;
  background-color: var(--gold);
  color: var(--darkgray);
  margin: 3px;
}

.tags button {
  all: unset;
}

.tags button {
  width: fit-content;
  height: fit-content;
  font-weight: 300;
  font-size: 10px;
  padding: 3px 8px 3px 8px;
}

.tags span {
  position: relative;
  top: 0px;
  padding-left: 0px;
  background-color: var(--lightred);
  justify-content: center;
  text-align: center;
  align-items: center;
  font-size: 12px;
  padding: 3px 2px 0px 2px;
}

.tags button.selected {
  background-color: var(--gold);
}

.tags button.selected:hover {
  background: var(--lightgray);
  color: white !important;
  font-weight: 400 !important;
}

.extra-user-features-container {
  padding-left: 20px;
  transition: all 0.6s ease-in;
}

.extra-features-delete-container {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: right;
  align-items: center;
  text-align: right;
  padding-right: 13px;
  margin-top: 5%;
  cursor: pointer;
}

.extra-features-delete-container .material-icons {
  position: relative;
  color: #8b8b8b;
  cursor: pointer;
  font-size: 18px;
}

.extra-features-delete-container .material-icons.red {
  color: var(--lightred);
}
