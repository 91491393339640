/* RADIO CONTAINER */
.gender-container {
  position: relative;
}

.gender-title {
  width: 250px;
  display: flex;
  position: absolute;
  top: -20px;
  font-size: 10px;
  justify-content: center;
}

.gender-container label {
  font-size: 10px;
  padding-right: 4px;
}

/* GENDER FILTER CONTAINER */
.gender-container {
  display: flex;
  flex-flow: row wrap;
  height: 30;
  cursor: pointer;
  width: fit-content;
  line-height: 18px;
}

.gender-container .elements {
}

.gender-container .element {
  position: relative;
  font-size: 13px;
  font-weight: 300px;
  padding: 0px 6px 0 1px;
}

.gender-container input {
  position: relative;
  cursor: pointer;
  top: 2px;
  margin: 0px 10px 0px 0px;
  padding-right: 5px;
  accent-color: #fed47e;
}
