* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-decoration: none;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  text-transform: uppercase;
}

a,
a.active,
a.link,
a.hover {
  all: unset;
  cursor: pointer;
}

html,
body {
  width: 100%;
  height: 100%;
}

body {
}

.container {
  display: flex;
  flex-flow: column;
}
