/* FILTER CONTAINER */
.find-filter-container {
  display: flex;
  flex-flow: column wrap;
  height: fit-content;
}

/* FILTERS TOP */
.filter-top {
  border-bottom: 1px solid rgb(0, 0, 0, 0.1);
  border-left: 5px solid transparent;
  z-index: 10;
  display: flex;
  height: fit-content;
  background: #f4f4f4;
}

.main-filters {
  z-index: -1;
  display: flex;
  flex-flow: row wrap;
  width: 100%;
}

.top-left-filter {
  display: flex;
  width: fit-content;
  padding: 30px 40px 0 40px;
  flex-flow: column;
  justify-content: left;
  flex-grow: 1;
}

.left-top {
  display: flex;
  flex-flow: row wrap;
}

.search-bar {
  display: flex;
  width: fit-content;
  padding: 5px 0px 0px 0px;
}

.search-bar input {
  all: unset;
  display: flex;
  box-sizing: border-box;
  width: 250px;
  height: 23px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.08);
  padding: 5px;
}

.search-bar input::placeholder {
  text-align: center;
}

input[type="radio"] {
  accent-color: rgb(170, 144, 15);
  accent-color: rgb(93, 142, 173);
}

.search-bar-filter {
  display: flex;
  justify-content: center;
  padding: 13px 0px 0px 20px;
}

fieldset {
  display: inline-flex;
  margin: 10px 10px 15px 10px;
  padding: 5px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.search-bar-filter label,
legend {
  display: flex;
  align-items: center;
  font-size: 9px;
}

.search-bar-filter input {
  padding: 5px;
  margin: 5px 15px 5px 5px;
  align-items: center;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.1);
}

/* Key Input Search Filter*/
.search-input-filter {
  display: flex;
  height: 23px;
  cursor: pointer;
  position: relative;
}

.search-dropdown-button {
  display: flex;
  height: 23px;
  width: 80px;
  margin: auto;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 6px;
  background-color: var(--gold);
  color: #4d4d4d;
  font-size: 11px;
  position: relative;
  padding-right: 8px;
}

.search-dropdown-button.after::after {
  font-family: "Material Icons";
  content: "\e5cf";
  position: absolute;
  right: 3px;
}

.search-dropdown-button::after {
  font-family: "Material Icons";
  content: "\e5ce";
  position: absolute;
  right: 3px;
  transition: 0.9s ease-in;
}

.search-input-dropdown {
  display: flex;
  transition: all 0.4s ease-in-out;
  flex-flow: column;
  overflow: hidden;
  max-height: 0px;
  height: fit-content;
  position: absolute;
  width: 80px;
  top: 23px;
  background-color: var(--shadowgray);
}

.search-input-dropdown.on {
  display: flex;
  height: fit-content;
  max-height: 100px;
  box-shadow: 0px 2px -1px rgba(0, 0, 0, 0.15);
}

.search-input-element {
  display: flex;
  font-size: 10px;
  height: fit-content;
  padding: 5px;
  margin: auto;
  width: 80px;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.search-input-element:hover {
  color: #4d4d4d;
  background-color: var(--gold);
}

.left-bottom {
  height: 20px;
  width: 100%;
}

/* TOP RIGHT FILTER */
.top-right-filter {
  z-index: -1;
  display: flex;
  flex-flow: column wrap;
  flex-grow: 1;
}

/* RIGHT TOP CONTAINER */
.right-top-container {
  display: flex;
  flex-flow: row wrap;
  margin-top: 10px;
  margin-bottom: 0px;
  justify-content: center;
  text-align: center;
}

.age-filter {
}

.height-filter {
}

.shoes-filter {
}

.clothes-filter {
}

.gender-filter {
  margin: 33px 0 33px 10px;
}

/* RIGHT BOTTOM CONTAINER */
.right-bottom-container {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  justify-content: center;
  text-align: center;
}

/* FILTER BOTTOM */
.filter-bottom {
  display: flex;
  height: fit-content;
  background: #f4f4f4;
  mix-blend-mode: normal;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  flex-flow: row wrap;
}

input.key-name-button {
  all: unset;
  background-color: var(--gold);
  color: black;
  font-size: 11px;
  padding: 0 5px 0 5px;
  cursor: pointer;
}
input.key-name-button:hover {
  background-color: var(--darkgray);
  color: white;
}

/* TAGS */
.tags-open-container {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  position: relative;
}

.tags-open {
  width: 100%;
  z-index: 10;
  display: flex;
  width: fit-content;
  font-size: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  justify-content: center;
  margin: auto;
  padding: 5px;
  position: absolute;
  top: -10px;
  right: 15px;
  background-color: white;
  border-radius: 5px;
  cursor: pointer;
}

.tags-open:hover {
  background-color: var(--gold);
  color: black;
  transition: all 0.5s ease-in;
}

.tags-open.active {
  background-color: var(--gold);
  color: black;
}

.tag-container.on {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  padding: 6px;
  height: fit-content;
  margin-top: 10px;
  position: relative;
  max-height: fit-content;
}

.tag-container {
  max-height: 0;
  height: fit-content;
  transition: all 0.3s ease-in-out;
  position: relative;
}

.tags-list.on {
  max-height: 0px;
  overflow: hidden;
  position: relative;
}

.tags-list {
  width: fit-content;
  font-weight: 300;
  margin: 4px 2px 4px 2px;
  cursor: pointer;
  height: fit-content;
  max-height: 100px;
}

.tags-list.set span {
  background: var(--gold);
  color: black;
}

.tags-list span {
  position: relative;
  background: var(--lightgray);
  color: white;
  font-size: 9px;
  font-weight: 400;
  letter-spacing: 0.5px;
  padding: 2px 5px 2px 5px;
}

.tags-list.selected {
  background-color: var(--gold);
  color: black;
}

.tags-list span:hover {
  background: var(--gold);
  color: black;
}
