/* Find Data Main Container */
.find-data-container {
  display: flex;
  flex-flow: column;
  width: 100%;
  background-color: white;
  z-index: 2;
}

/* MAIN CONTAINER */
.find-main-container {
  display: flex;
  flex-flow: row wrap;
  background-color: white;
  justify-content: center;
  margin-top: 14px;
}

.list-users {
  display: flex;
  width: fit-content;
  flex-flow: row wrap;
  justify-content: center;
  -webkit-justify-content: center;
  margin: auto;
}

/* Image Box */
.list-users figure {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  -webkit-justify-content: center;
  margin: 1px;
  background: #ffffff;
  border: 1px solid #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 190px;
  height: 260px;
  cursor: pointer;
  margin-bottom: 10px;
}

.list-users figure img {
  width: 190px;
  height: 240px;
  object-fit: cover;
  padding: 2px;
}

/* Bottom Image */
figcaption {
  all: unset;
  position: relative;
  width: 190px;
  z-index: 1;
  display: flex;
  flex-flow: column;
  margin: auto;
  height: fit-content;
  top: -31px;
}

/* top text */
.user-details {
  display: flex;
  flex-flow: row wrap;
  position: relative;
  width: 190px;
  height: 29px;
  background: rgba(255, 255, 255, 0.8);
}

/* top text items */
.user-name {
  display: flex;
  width: 100%;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  text-align: justify;
  letter-spacing: 0.01em;
  color: #000000;
  padding: 3px 6px 0px 6px;
}

.user-height,
.user-age,
.user-shoes,
.user-clothes {
  display: flex;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 7px;
  line-height: 8px;
  text-align: justify;
  letter-spacing: 0.01em;
  color: #4d4d4d;
  text-transform: none;
  padding: 0px 7px 3px 7px;
}

.user-age {
}
.user-shoes {
}
.user-clothes {
}

/* bottom text */
.user-containers {
  display: flex;
  flex-flow: row wrap;
  height: 25px;
}

/* bottom left container */
.user-social {
  display: flex;
  flex-flow: row wrap;
  padding: 5px 0px 0px 6px;
  height: 20px;
  flex-grow: 1;
}

.user-social .material-icons {
  padding-right: 10px;
  margin: 0;
  font-size: 12px;
  color: rgba(80, 80, 80, 0.8);
}

/* bottom left items */
.user-images {
  display: flex;
}
.user-video {
  display: flex;
}
.user-shared {
  display: flex;
}

/* bottom right */
.user-edit {
  display: flex;
  flex-flow: row wrap;
  padding: 5px 0px 0px 5px;
  height: 20px;
  color: rgba(80, 80, 80, 0.8);
}

.user-edit .material-icons {
  padding-right: 10px;
  margin: 0;
  font-size: 12px;
}

.more-container {
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 20px;
  cursor: pointer;
  height: fit-content;
  position: relative;
  z-index: 3 !important;
  top: -250px;
  height: 100px;
}

.more-scroll {
  display: flex;
  position: relative;
  width: 100%;
  height: 100px;
  width: fit-content;
}

.more-scroll span,
.more-scroll p {
  position: relative;
  top: 250px;
  justify-content: space-around;
  font-size: 20px;
  cursor: pointer;
  margin: auto;
  width: fit-content;
}

.top-scroll {
  z-index: 4;
  position: relative;
  top: 250px;
  justify-content: space-around;
  font-size: 20px;
  cursor: pointer;
  margin: auto;
  width: fit-content;
}

/* Counter / Context Menus */
.counter {
  display: flex;
  font-weight: 500;
  font-size: 10px !important;
  color: var(--darkgray);
  justify-content: center;
  width: 100%;
  position: relative;
  padding-top: 20px;
}

.messages {
  display: flex;
  font-weight: 500;
  font-size: 20px !important;
  color: var(--lightred);
  justify-content: center;
  width: 100%;
  position: relative;
  top: 30px;
}

.messages2 {
  display: flex;
  font-weight: 500;
  font-size: 20px !important;
  color: var(--lightred);
  justify-content: center;
  width: 100%;
  position: relative;
}
.counter-container {
  position: relative;
  top: 2px;
}

.counter.buttons {
  position: fixed;
  z-index: 3;
  padding: 2px;
  background-color: rgba(0, 0, 0, 0.8);
  width: 250px;
  margin-left: 10px;
  border-radius: 5px;
  width: fit-content;
}

.counter.buttons button {
  all: unset;
  padding: 2px 4px 2px 4px;
  margin: 5px;
  color: rgb(0, 0, 0);
  font-size: 9px;
  background-color: var(--gold);
  border: 1px solid var(--gold);
  cursor: pointer;
}

.counter.buttons button:hover {
  background-color: black;
  color: var(--gold);
  border: 1px solid var(--gold);
}

.counter.count {
  /* margin-top: 15px;*/
}

.button-close .material-icons {
  display: flex;
  padding: 6px 6px 0 5px;
  color: white;
  font-size: 15px;
  cursor: pointer;
  transition: all 0.6s ease-in;
}

.counter.buttons {
  transition: all 0.9s ease-in;
  left: -2px;
  top: 190px;
  z-index: 10;
}

.counter.buttons.close-modal {
  z-index: 10;
  left: -248px;
}
