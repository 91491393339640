.left-container {
  display: flex;
  flex-flow: column;
  width: 128px;
  height: fit-content;
  position: relative;
}

.left-container ul {
  display: flex;
  flex-flow: column;
  margin: auto;
}

.left-container li {
  display: flex;
  flex-flow: column;
  width: 128px;
  height: 100px;
  text-align: center;
  line-height: 15px;
  color: #8b8b8b;
  cursor: pointer;
  font-weight: 300;
  background: linear-gradient(#ffffff, #ffffff) 50% 50% / calc(100% - 1px)
      calc(100% - 1px) no-repeat,
    linear-gradient(
      90deg,
      transparent 0%,
      rgba(122, 122, 122, 0.1) 46%,
      #f9f9f9 100%
    );
}

.left-container li a {
  width: 128px;
  height: 135px;
}

.left-container li p {
  position: relative;
  top: 50px;
}

.left-container li.top,
li.bottom {
}

.left-container .line {
  z-index: 1;
  width: 110px;
  color: red;
}

.left-container li a.active {
}

.left-container li:hover {
  background-color: var(--lightgray);
}
