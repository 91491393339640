.nationality-container-add {
  display: flex;
  position: relative;
}

.nationality-container {
  display: flex;
  flex-flow: column;
  position: absolute;
  right: 0px;
  top: 23px;
  width: fit-content;
}

.nationality-container-add {
}

.nationality-container input {
  all: unset;
  font-size: 10px;
  line-height: 14px;
  color: #4d4d4d;
  width: 155px;
  height: 18px;
  color: rgba(139, 139, 139, 1);
  background: #eaeaea;
  border-bottom: 1px solid #ffffff;
  box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.25);
  text-align: left;
  padding-left: 10px;
}

.nationality-container input::placeholder {
}

.nationality-container button {
  all: unset;
  display: flex;
  background-color: var(--gold);
  padding: 3px 5px 3px 0px;
  justify-content: center;
  text-align: center;
  align-items: center;
  font-size: 10px;
}
