footer {
  z-index: 10;
  display: flex;
  flex-flow: row;
  margin: auto;
  justify-content: center;
  text-align: center;
  align-items: center;
  width: 100%;
  padding: 10px;
  height: 60px;
}
