.features-container {
  position: absolute;
  width: 165px;
  z-index: 1;
}

.dropdown {
  width: 165px;
  justify-content: center;
  text-align: center;
  align-items: center;
  cursor: pointer;
  margin-bottom: 11px;
}

.dropdown-button,
.dropdown-button-add {
  transition: 0.1s;
  z-index: 1;
  display: flex;
  width: 165px;
  height: 22px;
  background: #d9d9d9;
  color: #000000;
  margin: auto;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.dropdown:hover {
  background-color: #dadada;
  transition: all 1s ease-in;
}

.dropdown-button {
  color: white;
  background-color: #aaaaaa;
}

/* Dropdown arrow after */
.dropdown-button::after {
  right: 2px;
  font-family: "Material Icons";
  content: "\e5cf";
  position: absolute;
  font-size: 20px;
  transition: ease-in-out 0.4s;
}

.dropdown:hover .dropdown-button::after {
  font-family: "Material Icons";
  content: "\e5cf";
  position: absolute;
  font-size: 20px;
  transform: rotate(0.5turn);
  transition: ease-in-out 0.4s;
}

/* Dropdown Content */
.dropdown-content {
  z-index: 10;
  position: absolute;
  background-color: #e3e3e3;
  min-width: 165px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  transition: all 0.4s ease-in;
  overflow: hidden;
  height: fit-content;
  max-height: 0;
}

.dropdown-content.show {
  display: block;
  background: #d9d9d9;
  transition: all 0.4s ease-in;
  max-height: 200px;
  height: fit-content;
}

/* Drop Down Elements Hover *
.dropdown-content .dropdown-element:hover {
  background: #fed47e;
}

/* Drop Down ON HOVER Show Elements *
.dropdown:hover .dropdown-content {
  display: block;
  height: fit-content;
  max-height: 100%;
}

.dropdown-content.on {
  display: block;
  height: fit-content;
  max-height: 100%;
}
*/

.dropdown-element p {
  font-size: 10px;
  line-height: 14px;
  padding: 1px;
  color: #4d4d4d;
}

.dropdown-selected {
  background-color: #fed47e;
  font-size: 10px;
  line-height: 14px;
}

/* Nationality Custom DropDown */
.dropdown-button.add {
  background-color: #aaaaaa;
  color: white;
}

.buttons-container {
  display: flex;
  width: 165px;
  height: 22px;
  transition: all 0.6s ease-in;
}

.dropdown-button-add,
.dropdown-button-add:after {
  width: 25px;
  font-family: "Material Icons";
  content: "\e145";
  transition: ease-in-out 0.4s;
  font-size: 15px;
}

.dropdown-button-add:after {
  color: var(--darkgray);
}

.dropdown-button-add:hover:after {
  font-family: "Material Icons";
  content: "\e15b";
  color: var(--lightgray);
  transform: rotate(0.5turn);
  transition: ease-in-out 0.4s;
}

.dropdown-button-add.open:after {
  font-family: "Material Icons";
  content: "\e15b";
}

.dropdown-add {
  position: relative;
  justify-content: center;
  text-align: center;
  align-items: center;
  cursor: pointer;
}

.dropdown-button.add::after,
.dropdown-add:hover:after {
  content: "";
}

.dropdown-add-nationality {
  background-color: var(--lightgray);
}

.dropdown-add-nationality .material-icons {
  font-size: 18px;
  padding: 2px 2px 0 3px;
  background-color: var(--lightgray);
  color: white;
}

.confirm-delete {
  user-select: none;
  z-index: 0;
  display: flex;
  flex-flow: column;
  position: relative;
  color: white;
  width: 100%;
  height: fit-content;
  font-size: 8px;
  background-color: rgba(0, 0, 0, 0.7);
  justify-content: center;
  text-align: center;
  align-items: center;
  padding: 0px 10px 20px 10px;
}

.confirm-delete p {
  padding: 10px;
  font-size: 11px;
}

.confirm-delete .button {
  all: unset;
  width: fit-content;
  height: fit-content;
  padding: 4px;
  margin: 4px;
  font-size: 10px;
  background-color: var(--gold);
  color: #000000;
  font-weight: 400;
}

.confirm-delete .button.green {
  background-color: green;
  color: white;
}

.confirm-delete .button.green:hover {
  background-color: white;
  color: rgb(0, 0, 0);
}

.confirm-delete .button:hover {
  background-color: var(--lightred);
  color: white;
}
