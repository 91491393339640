.update-data-wrapper {
  display: flex;
  width: 597px;
  justify-content: left;
  background-color: rgba(255, 255, 255, 0.8);
}

.delete-user-container {
  display: flex;
  position: absolute;
  right: 15px;
  top: 7px;
  cursor: pointer;
  z-index: 1;
}

.material-icons.del {
  font-size: 20px;
  color: var(--lightgray);
}

.material-icons.del:hover {
  color: var(--darkgray);
  font-size: 20px;
}

.del {
  cursor: pointer;
}

.confirm-delete-container {
  display: flex;
  position: absolute;
  right: 15px;
  top: 30px;
  z-index: 10;
  padding: 14px;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 2px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
}

.cancel-delete-user,
.confirm-delete-user {
  all: unset;
  padding: 2px 4px 2px 4px;
  margin: 5px;
  color: rgb(0, 0, 0);
  font-size: 10px;
  background-color: var(--gold);
  border: 1px solid var(--gold);
  cursor: pointer;
}

.confirm-delete-user {
  color: rgb(190, 2, 2);
}

.cancel-delete-user:hover,
.confirm-delete-user:hover {
  background-color: var(--black);
  color: var(--gold);
  border: 1px solid var(--gold);
}
