.special-notes {
  display: flex;
  flex-flow: column;
}
.special-notes p {
}
.special-notes textarea {
  all: unset;
  display: flex;
  width: 201px;
  height: 51px;
  color: rgba(139, 139, 139, 1);
  background: #eaeaea;
  border-bottom: 1px solid #ffffff;
  box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.25);
  padding: 10px;
  margin-bottom: 10px;
}
