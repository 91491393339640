.exclusive-container {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  width: fit-content;
  padding-left: 15px;
}

.exclusive-title {
  color: var(--lightgray);
  font-size: 10px;
  margin-right: 15px;
}

.exclusive-select {
  display: flex;
  width: 80px;
  cursor: pointer;
  color: var(--lightgray);
}

.exclusive-select .material-icons {
  font-size: 14px;
  line-height: 12px;
  color: var(--lightgray);
}

.exclusive-select .material-icons.clear {
  color: var(--lightgray);
}

.exclusive-select .material-icons.filled {
  color: var(--gold) !important;
}

.exclusive-select:hover,
.exclusive-select .exclusive-text {
  color: var(--darkgray);
  font-weight: 500;
}

.exclusive-select p {
  all: unset;
  position: relative;
  font-size: 10px;
  top: -3px;
  padding-left: 4px;
}
