/* FULL FORM CONTAINER */
form {
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100%;
  position: relative;
}

/* CONTENT START */
form .container-start {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  margin-bottom: 20px;
  padding: 10px 0 0 20px;
}

.container-id {
  display: flex;
  flex-flow: row wrap;
  flex-grow: 1;
}
.container-active {
  display: flex;
  flex-flow: row wrap;
  flex-grow: 1;
  justify-content: right;
  align-items: center;
  text-align: right;
  padding-right: 120px;
}

/* CONTENT TOP */
form .container-top {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  height: fit-content;
  margin-bottom: 10px;
}

.container-top .inputs-fields-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: left;
  align-items: top;
}

/* Form Inputs */
.form-set input,
.form-set label {
  all: unset;
  width: 165px;
  height: 25px;
  font-size: 10px;
  color: rgba(139, 139, 139, 1);
  background: #eaeaea;
  border-bottom: 1px solid #ffffff;
  box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.25);
  margin-bottom: 8px;
  margin-right: 14px;
}

.form-set input::placeholder,
.form-set {
  padding-left: 5px;
  font-size: 10px;
}

.form-set input {
  padding-left: 5px;
}

/* Form Profile Picture */
.container-top .profile-photo-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: top;
  margin: 0 20px 0 5px;
}

/* Form Dropdown */
.container-top .dropdowns-container {
  display: flex;
  flex-flow: row wrap;
}

/* CONTENT MIDDLE */
form .container-middle {
  display: flex;
  flex-flow: column;
  margin-bottom: 20px;
}

/* Dress Size */
.container-middle .dress-container {
  display: flex;
  margin-bottom: 20px;
}

/* Exclusive */
.container-middle .exclusive-user-container {
  display: flex;
  margin-bottom: 20px;
}

/* SnapShots */
.container-middle .snapshots-container {
  display: flex;
  margin-bottom: 20px;
}

/* Video */
.container-middle .video-container {
  display: flex;
}

/* CONTENT BOTTOM */
form .container-bottom {
  display: flex;
  flex-flow: column;
}

.container-bottom .extra-user-features-container {
  display: flex;
  transition: all 0.6s ease-in;
}

/* Underline */
.container-bottom .under-line {
  display: flex;
  margin: 3px 15px 3px 15px;
  font-size: 1px;
  background-color: var(--darkgray);
  opacity: 0.1;
}

/* Extra Features Tags */
.container-bottom .extra-features-tags-container {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
}

/* Special Notes and Submit  */
.container-bottom .special-notes-submit-container {
  display: flex;
  flex-flow: row wrap;
  flex-grow: 1;
  padding-left: 20px;
}

/* Special Notes and Submit  */
.container-bottom .special-notes-container {
  display: flex;
  flex-flow: column;
  flex-grow: 2;
}

/* Submit Button Container  */
.container-bottom .submit-container {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 20px;
}

/* Submit Button */
.container-bottom .submit-button-container {
  display: flex;
}

.form-set .submit {
  cursor: pointer;
  border: none;
  width: 136px;
  height: 42px;
  background-color: rgba(254, 212, 126, 1);
  font-size: 12px;
  line-height: 15px;
  color: rgba(139, 139, 139, 1);
  margin: 0 10px 0 10px;
  justify-content: center;
  text-align: center;
  align-items: center;
  font-weight: 400;
}

.form-set .submit:hover {
  color: var(--darkgray);
  font-weight: 500;
}

/* Submit Info */
.container-bottom .info-container {
  display: flex;
  padding-top: 10px;
  justify-content: right;
  text-align: right;
  width: 100%;
  margin-right: 40px;
}

.container-bottom .info-container p {
  font-weight: 400;
  font-size: 7px;
  line-height: 8px;
  color: #8b8b8b;
  padding-bottom: 10px;
}
