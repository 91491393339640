nav {
  display: flex;
  width: fit-content;
  height: fit-content;
  margin: 0px 0px 0px 0;
  padding: 0;
  justify-content: left;
  align-items: center;
  text-align: center;
  flex-grow: 1;
}

nav li {
  display: inline-flex;
  width: fit-content;
  color: #8b8b8b;
  font-weight: 300;
  justify-content: space-between;
}

nav li::after {
  font-family: "Material Icons";
  content: "\e5d4";
  padding: 2px 8px 0 8px;
}

nav li.last::after {
  content: "";
}

nav li:hover {
  color: #4d4d4d;
}

nav li a.active {
  color: #4d4d4d;
  font-weight: 500;
}

nav li.first {
  padding: 0 0px 0 8px;
}

nav li.first::after {
  padding-left: 0px;
}
