.add-data-container {
  position: relative;
  display: flex;
  flex-flow: column;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.8);
}

.updated {
  color: var(--gold);
  background-color: black;
  justify-content: center;
  margin: auto;
  text-align: center;
  padding: 0 13px 0 13px;
}

.add-data-wrapper {
  display: flex;
  width: 597px;
  justify-content: left;
  background-color: rgba(255, 255, 255, 0.8);
}
